<template>
  <teleport to="body">
    <VModal
      v-if="getIsOpenModal"
      @close="closeModal"
      max-width-class="width-820"
    >
      <h4 class="modal__title mb-40">Фиктивный участник</h4>

      <VMask
        regex="00:00"
        mask-custom="00:00"
        :mask-type="true"
        is-range
        :is-masked-value="true"
        :range="range"
        id="step_after_pause_time"
        name="step_after_pause_time"
        :class-input="['input input-long input-placeholder-black']"
        v-model="formData.step_after_pause_time"
        :errors="v$.step_after_pause_time.$errors"
        :server-errors="serverErrors.value?.step_after_pause_time"
        @blur="validateField"
        @input="validateField"
        placeholder="Промежуток времени повторного снижения ставки"
      />

      <VInput
        name="after_pause_sum"
        id="after_pause_sum"
        type="number"
        v-model="formData.after_pause_sum"
        :errors="v$.after_pause_sum.$errors"
        :server-errors="serverErrors.value?.after_pause_sum"
        @blur="validateField"
        @input="validateField"
        classes="select-border-bottom"
        class="input-long"
        placeholder="Сумма снижения ставки после паузы в руб."
      />

      <VInput
        name="min_sum"
        id="min_sum"
        type="number"
        v-model="formData.min_sum"
        :errors="v$.min_sum.$errors"
        :server-errors="serverErrors.value?.min_sum"
        @blur="validateField"
        @input="validateField"
        classes="select-border-bottom"
        class="input-long"
        placeholder="Сумма окончания снижения ставки"
      />

      <VCheckbox
        is-switch
        id="is_active"
        name="is_active"
        class-label="bg-white"
        class-form="form-checkbox"
        :value="formData.is_active"
        v-model="formData.is_active"
        :errors="v$.is_active.$errors"
        :server-errors="serverErrors.value?.is_active"
        @blur="validateField"
        @input="validateField"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Активировать участника
          </label>
        </template>
      </VCheckbox>

      <div class="modal__buttons">
        <VButtonLoading
          type="button"
          @click="onSubmit"
          :is-disabled="isSend"
          :is-errors="v$.$error"
          color="green"
        >
          Сохранить
        </VButtonLoading>
      </div>

    </VModal>
  </teleport>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue'
import VInput from '@/components/ui/form/VInput'
import VMask from '@/components/ui/form/VMask'
import VModal from '@/components/ui/modal/VModal'
import VButtonLoading from '@/components/ui/buttons/VButtonLoading'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import { useFictiveUser } from '@/use/Form/Lot/FictiveUser/useFictiveUser'

defineEmits(['open', 'close'])

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  closeModal: {
    type: Function,
    required: true
  }
})

const range = {
  hours: {
    min: 0,
    max: 23,
    pos: [0, 1]
  },
  min: {
    min: 0,
    max: 59,
    pos: [3, 4]
  }
}

const getIsOpenModal = computed(() => {
  return props.isOpen
})

const { formData, v$, validateField, serverErrors, onSubmit, isSend } = useFictiveUser(props.closeModal)
</script>
