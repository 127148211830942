import store from '@/store'
import { LotController } from '@/controllers/LotController'

export class ParticipantService {
  constructor({ lotId, isParticipant, isSend }) {
    this.lotController = new LotController()
    if (!lotId) {
      throw new Error('LotId is undefined')
    }
    this.lotId = lotId
    this.isSend = isSend
    this.isParticipant = isParticipant
  }

  checkIsParticipant(data) {
    if (!data.bidder) {
      return false
    }

    return !!data.bidder.find(user => user.id === store.getters['auth/getUser'].id)
  }

  async accept() {
    this.isSend.value = true
    await this.lotController.attach(this.lotId)
    this.isParticipant.value = true
    this.isSend.value = false
  }

  async decline() {
    this.isSend.value = true
    await this.lotController.detach(this.lotId)
    this.isParticipant.value = false
    this.isSend.value = false
  }
}
