import { computed, inject, onBeforeMount, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useValidateField } from '@/use/Form/useValidateField'
import { MEASUREMENT } from '@/utils/manual/manualList'
import { manualMemoization } from '@/utils/memoization/manualMemoization'

export function useTableCreateTrade(positions, isEditBidder, isOrganizer) {
  const store = useStore()
  const lot = store.getters['lot/getLot']
  const { getPriceMinMaxCustom, getPriceErrorMsgCustom } = useValidateField(null, null, lot)
  const observer = inject('observer')
  const measurement = reactive([])

  onBeforeMount(async () => {
    measurement.length = 0
    measurement.push(...await manualMemoization(true, MEASUREMENT))
  })

  const headRows = [
    {
      name: '№',
      value: 'idx'
    },
    {
      name: 'Наименование',
      value: 'name',
      width: '105px'
    },
    {
      name: 'Хар-ка товара',
      width: '120px',
      value: 'table_characteristic',
      decorator: lot.analog_acceptable && !isOrganizer
        ? {
          name: 'input',
          key: 'characteristic',
          type: 'string',
          isDisabled: !isEditBidder
        }
        : null
    },
    {
      name: 'Ед. измерения',
      value: 'table_measurement_name',
      width: '120px',
      decorator: lot.analog_acceptable && !isOrganizer
        ? {
          name: 'select',
          key: 'measurement_id',
          type: 'string',
          options: measurement,
          isDisabled: !isEditBidder
        }
        : null
    },
    {
      name: 'Кол-во',
      value: 'table_quantity',
      decorator: !isOrganizer
        ? {
        name: 'input',
        key: 'quantity',
        size: 'small',
        type: 'number',
        isDisabled: !isEditBidder
      }
      : null
    },
    {
      name: `${isOrganizer ? 'Начальная' : 'Ваша'} цена`,
      value: isOrganizer ? 'start_price' : 'table_price',
      decorator: !isOrganizer
        ? {
          name: 'input',
          size: 'small',
          key: 'price',
          type: 'number',
          isDisabled: !isEditBidder
        }
        : null
    },
    {
      name: 'Лучшая цена',
      value: 'bestPrice',
      width: '105px'
    },
    {
      name: 'Доп. параметры',
      value: 'parameters',
      decorator: {
        name: 'ModalParameter',
        isRead: !isEditBidder
      }
    }
  ]

  if (!isOrganizer) {
    headRows.splice(-1, 0, {
      name: 'Примечание участника',
      value: 'table_note',
      decorator: !isOrganizer
        ? {
          name: 'input',
          key: 'participant_note',
          type: 'string',
          isDisabled: !isEditBidder
        }
        : null
    },)
  }

  const validField = ['price']
  const observerField = ['price', 'quantity']

  const getCurrentCell = computed(() => store.getters['cell/getCurrentCell'])
  watch(getCurrentCell, () => {
    // get current pos and change value
    const currentPosition = positions.find(pos => pos.id === getCurrentCell.value.row.id)
    currentPosition[getCurrentCell.value.key] = getCurrentCell.value.content

    if (getCurrentCell.value.key === 'measurement_id') {
      currentPosition.measurement = store.getters['manual/getManualByKey'](MEASUREMENT)[getCurrentCell.value.key]
    }

    // get offer and calculate price { min, max }

    // for reCalc summary
    if (observerField.includes(getCurrentCell.value.key)) {
      observer.broadcast(currentPosition)
    }

    if (!validField.includes(getCurrentCell.value.key)) return
    // for validate
    const offer = store.getters['lot/getLastPrice'].last_offers?.find(offer => offer.answers.position.id === currentPosition.id)
    const res = getPriceMinMaxCustom(currentPosition, offer)
    // For display error input filed

    if (res.min !== 0 && !res.min) {
      store.commit('cellValidate/dropErr', currentPosition.id)
    }

    if (res.min > currentPosition[getCurrentCell.value.key] || currentPosition[getCurrentCell.value.key] > res.max) {
      if (!res.max) return
      const obj = { msg: getPriceErrorMsgCustom(res.min, res.max, offer), id: currentPosition.id, key: getCurrentCell.value.key }
      store.commit('cellValidate/setErr', obj)
    } else {
      store.commit('cellValidate/dropErr', currentPosition.id)
    }
  })

  const transformPositions = () => {
    return positions.map((position, idx) => {
      position.table_price = position.price
      position.table_quantity = position.quantity
      position.table_note = position.participant_note
      position.table_measurement_name = position.measurement.name
      position.table_characteristic = position.characteristic
      position.table_note = position.participant_note
      position.idx = idx + 1
      return position
    })
  }

  const rows = reactive(transformPositions())
  return {
    headRows,
    rows
  }
}
