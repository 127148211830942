<template>
  <div
    class="mb-20"
  >
    <VTable
      :headRow="headRows"
      :rows="rows"
      :scroll="isScroll"
      :is-pagnitaion="false"
      :is-min-height="false"
      :is-checkbox="false"
      class-head="border"
    >
      <slot name="decorator" />
    </VTable>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useTableCreateTrade } from '@/use/table/useTableCreateTrade'
import VTable from '@/components/ui/table/VTable'

const props = defineProps({
  positions: {
    type: Array,
    required: true
  },
  isBidderActive: {
    type: Boolean,
    required: false
  },
  isOrganizer: {
    type: Boolean,
    required: false
  },
  isScroll: {
    type: Boolean,
    required: false,
    default: true
  }
})
const { headRows, rows } = useTableCreateTrade(props.positions, props.isBidderActive, props.isOrganizer)
</script>
