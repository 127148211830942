<template>
  <div
    class="text-block"
    :class="{'text-block--gap-20' : fontSizeDescription === '38'}"
  >
    <h4 class="text-block__subtitle flex align-center">
      {{ subtitle }}:
      <slot name="popupHead" />
    </h4>
    <a
      v-if="isLink || isEmail || isTel"
      class="text-block__link"
      :class="{'text-block__link--underline' : isLink || isEmail}"
      :href="href"
      :target="isLink ? '_blank' : ''"
    >
      {{ getDescription }}
    </a>

    <div
      v-else
    >
      <component
        v-if="decorator?.name"
        :is="getComponent()"
        :classNameDescription="classNameDescription"
        :description="getDescription"
        :options="decorator.options"
      />

      <p
        v-else
        :class="['text-block__description', decorationClass, classNameDescription]"
      >
        {{ getDescription }}
        <slot name="popup" />
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import VTextBlockDecoratorStatus from '@/components/textblock/decorator/VTextBlockDecoratorStatus'

export default {
  props: {
    subtitle: {
      type: String
    },
    description: {
      type: [Number, String, Boolean]
    },
    decorationClass: {
      type: String,
      required: false
    },
    fontSizeDescription: {
      type: String,
      required: false,
      default: '',
      validate (val) {
        return ['24', '38'].includes(val)
      }
    },
    isEmail: {
      type: Boolean,
      required: false,
      default: false
    },
    isLink: {
      type: Boolean,
      required: false,
      default: false
    },
    isTel: {
      type: Boolean,
      required: false,
      default: false
    },
    decorator: {
      type: Object,
      required: false
    },
    isPopup: {
      type: Boolean,
      required: false
    }
  },
  setup (props) {
    const classNameDescription = computed(() => {
      if (!props.fontSizeDescription) return ''
      return `text-block__description--fsz-${props.fontSizeDescription}`
    })

    const href = computed(() => {
      if (props.isLink) return 'http://www.' + props.description

      if (props.isEmail) return 'mailto:' + props.description

      if (props.isTel) return 'tel:' + props.description

      return '#'
    })

    const getComponent = () => {
      return `VTextBlockDecorator${props.decorator.name}`
    }

    const getDescription = computed(() => {
      if (typeof props.description === 'boolean') {
        return props.description ? 'Да' : 'Нет'
      }
      return props.description
    })

    return {
      getComponent,
      getDescription,
      classNameDescription,
      href
    }
  },
  components: {
    VTextBlockDecoratorStatus
  }
}
</script>
