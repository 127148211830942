<template>
  <p
    class="text-block__description"
    :class="classNameDescription"
  >
    <span class="status-circle" :class="getStatus()"></span>
    {{ description }}
  </p>
</template>

<script>
import { lowerCase } from '@/utils/utils'

export default {
  props: {
    classNameDescription: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: false
    }
  },
  setup (props) {
    const successStatusName = [
      'опубликован',
      'открыт',
      'продлён',
      'торги начались'
    ]

    const getStatus = () => {
      return successStatusName.includes(lowerCase(props.description)) ? 'success' : 'error'
    }

    return {
      getStatus
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";
.status-circle {
  position: relative;
  transform: translateY(-50%);

  margin-right: 5px;
  display: inline-block;
  width: 7px;
  height: 7px;

  border-radius: 50%;

  &.error {
    background-color: $color-red;
  }

  &.success {
    background-color: $color-green;
  }
}
</style>
