<script setup>
import VLotDetail from '@/components/lot-partial/VLotDetail'
import VLotInfoHead from '@/components/views/Lot/Info/Part/VLotInfoHead'
import VLotInfoBase from '@/components/views/Lot/Info/Part/VLotInfoBase'
import VTable from '@/components/ui/table/VTable'
import VIcon from '@/components/ui/icon/VIcon'
const head = [
  {
    name: 'Дата подачи',
    value: 'dateCreate'
  },
  {
    name: 'Продление лота',
    value: 'user_id', 
    tag_property: 'user_id',
    tag_value: 'Продлен организатором',
    tag_empty: 'Продлен автоматически',
    decorator: {
      name: 'Tag'
    }
  },
  {
    name: 'Время подачи',
    value: 'timeCreate'
  },
  {
    name: 'Участник',
    value: 'participant_name'
  },
  {
    name: 'Информация о ставке',
    value: 'link',
    decorator: {
      name: 'Action'
    }
  }
]
</script>

<template>
  <VLotDetail>
    <template #header="{ headerInfo, lot, isOrganizer }">
      <VLotInfoHead :date-info="headerInfo" :lot="lot" :is-organizer="isOrganizer" />
    </template>

    <template #content="{ lot, v$, validateField, formData, serverErrors, isBidder, isBidderActive, isEditDelivery, isEditPayment, isOrganizer, historyOffers }">
      <VLotInfoBase
        :lot="lot" :v$="v$"
        :validate-field="validateField"
        :server-errors="serverErrors"
        :form-data="formData"
        :is-bidder="isBidder"
        :is-organizer="isOrganizer"
        :is-bidder-active="isBidderActive"
        :is-edit-delivery="isEditDelivery"
        :is-edit-payment="isEditPayment"
      >
        <template #history>
          <VTable
            v-if="historyOffers.length"
            :headRow="head"
            :rows="historyOffers"
            height="800px"
            scroll
            :is-pagination="false"
            :is-min-height="false"
            :is-checkbox="false"
            class-head="border"
          >           
            <template #decorator="{ row }">
              <button
                v-if="'is_fake' in row"
                class="btn btn-primary btn-bg-transparent btn-hover-eye "
                @click="$router.push({ name: 'bidder-lot-edit', params: { id: $route.params.id, userId: row.user_id }, query: { sortId: row.sort, isFake: row.is_fake } })"
              >
                <v-icon icon-name="Eye" width="25" height="16" class="mr-10"></v-icon>
                Посмотреть
              </button>
            </template>
          </VTable>
        </template>
      </VLotInfoBase>
    </template>
  </VLotDetail>
</template>
