<template>
  <ul :class="['info', getTypeClass]">
    <li
      :class="['info__element', getTypeClass, i === info.length -1 && 'ml-60']"
      v-for="(item, i) in info" :key="i"
    >
      <VTextBlock
        :class="item.className"
        :description="item.description"
        :subtitle="item.title"
        :decorator="item.decorator"
      />
    </li>
  </ul>
</template>

<script>
import { computed } from 'vue'
import VTextBlock from '@/components/textblock/VTextBlock'

export default {
  components: { VTextBlock },
  props: {
    type: {
      type: String,
      required: true
    },
    info: {
      type: Array,
      required: true
    }
  },
  setup (props) {
    const getTypeClass = computed(() => {
      return props.type
    })

    return {
      getTypeClass
    }
  }
}
</script>

<style scoped>
.mr-80 {
  margin-right: 80px;
}

.ml-60 {
  margin-left: 60px;
}
</style>
