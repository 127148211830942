const conditionDefault = 'Поставщик может подать только одно ценовое предложение.'
const conditionAuction = 'Поставщик может подать неограниченное количество предложений.'

const text = 'Вы можете указать любую цену при отсутствии цены установленной организатором. Равной или ниже - относительно стартовой.'

export default {
  1: {
    type: 'Открытый конкурс',
    condition: conditionDefault,
    text
  },
  2: {
    condition: conditionAuction,
    type: 'Аукцион с понижением цены',
    text: ' Каждая следующая ставка должна иметь цену ниже, чем предыдущая'
  },
  3: {
    condition: conditionDefault,
    type: 'Запрос котировок',
    text
  },
  4: {
    condition: conditionDefault,
    type: 'Запрос предлоежний',
    text
  }
}
