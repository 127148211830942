class ValidateLogger {
  static validateArg(obj, props) {
    let isValid = true

    for (let i = 0; i < props.length; i++) {
      const prop = props[i]

      // eslint-disable-next-line valid-typeof
      if (typeof obj[prop[0]] !== prop[1]) {
        console.log('Невалидные данные по ключу: ', prop[0])
        console.log('Входной параметр одижает значение типа: ', prop[1])
        console.log('Входной параметр получен со значением типа: ', typeof obj[prop[0]])
        isValid = false
        break
      }
    }

    return !isValid
  }
}

class Logger {
  static logging(type, { groupName, log, path }) {
    if (ValidateLogger.validateArg(
      { groupName, log, path },
      [['groupName', 'string'], ['log', 'object'], ['path', 'string']]
    )) return
    console.group(groupName)
    console[type](log)
    console.log(`File: ${path}`)
    console.groupEnd()
  }
}

export class ConsoleLogger {
  static defaultLogWarn(...args) {
    Logger.logging('warn', ...args)
  }

  static defaultLogError(...args) {
    Logger.logging('error', ...args)
  }

  static defaultLogSuccess(...args) {
    Logger.logging('log', ...args)
  }
}
