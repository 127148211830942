import { useForm } from '@/use/Base/useForm'
import { onMounted, reactive, ref } from 'vue'
import { requiredValid } from '@/utils/validator/fields'
import { FormHandler } from '@/utils/use/formHandler'
import { changeFictiveBidder, createFictiveBidder, getFictiveBidder } from '@/http/fictiveBidder/fakeBidderApi'
import { Alert } from '@/extension/Alert/Alert'
import { useRoute } from 'vue-router'
import { helpers, minLength } from '@vuelidate/validators'

export function useFictiveUser (closeModal) {
  const route = useRoute()
  const isEdit = ref(false)
  const lotId = route.params.id

  const formData = reactive({
    step_after_pause_time: '',
    after_pause_sum: '',
    min_sum: '',
    is_active: false
  })

  const rules = {
    step_after_pause_time: {
      ...requiredValid,
      minLength: helpers.withMessage('Укажите занчеие времени в формате xx - минут: xx - часов', minLength(5))
    },
    after_pause_sum: requiredValid,
    min_sum: requiredValid,
    is_active: requiredValid
  }

  onMounted(async () => {
    try {
      const { data } = await getFictiveBidder(lotId)
      if (data) {
        isEdit.value = true
        const keys = Object.keys(formData)
        keys.forEach((key) => {
          formData[key] = data[key]
        })
      }
    } catch (e) {
      await Alert.show('error', 'Не удалось получить данные о фиктивном пользователе')
    }
  })

  const formAction = async () => {
    if (isEdit.value) {
      await changeFictiveBidder(lotId, formData)
      await Alert.show('success', 'Изменения сохраненны')
    } else {
      await createFictiveBidder(lotId, formData)
      await Alert.show('success', 'Данные сохраненны')
    }
    closeModal()
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors),
    serverErrors: form.serverErrors
  }
}
