<template>
  <div class="flex space-between">
    <div>
      <h2 class="block__subtitle mb-20">
        {{ lot.id }}
      </h2>
      <h3 class="lot-info__subtitle block__subtitle-small mb-20">
        {{ lot?.name }}
      </h3>
    </div>
    <div v-if="isOrganizer">
      <v-link
        v-if="checkCurrentStatus([1, 3, 4])"
        class="mb-20 ml-a normal-weight"
        icon="Edit"
        @click="goChangeLot"
      >
        Редактировать
      </v-link>

      <button
        class="btn btn-primary btn-primary--padding btn-bg-green"
        @click="openVModal"
      >
        Фиктивный участник
      </button>
      </div>
  </div>

  <div class="p-30 bg--yellow mb-20">
    <StatusInfo
      :info="dateInfo"
      type="bidder--lot"
    />
  </div>

  <teleport to="body">
    <TheModalFictiveBidder
      v-if="isOrganizer"
      :is-open="showVModal"
      :close-modal="closeVModal"
    />
  </teleport>
</template>

<script >
import StatusInfo from '@/components/statusElements/VStatusInfo'
import TheModalFictiveBidder from '@/components/ui/modal/lot/TheModalFictiveBidder'
import { checkCurrentStatus } from '@/utils/lot/function.lot'
import { lotInfoProps } from '@/mixin/props'
import { useRouter } from 'vue-router'
import useVModal from '@/use/Base/useVModal'
import VLink from '@/components/ui/buttons/VLink'

export default {
  mixins: [lotInfoProps],
  props: {
    dateInfo: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const router = useRouter()
    const goChangeLot = () => {
      router.push({ name: 'edit-trade', params: { id: props.lot.id } })
    }

    return {
      goChangeLot,
      checkCurrentStatus,
      ...useVModal()
    }
  },
  components: {
    VLink,
    StatusInfo,
    TheModalFictiveBidder
  }
}
</script>
