<script setup>
import VIconPopup from '@/modules/popup/VIconPopup'
import { defineEmits, defineProps, computed } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  classDialog: {
    type: String,
    required: false
  },
  modelValue: {
    type: Boolean,
    required: false
  }
})

const isDialog = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>

<template>
  <span class="popup">
     <v-icon-popup
       @mouseenter="isDialog = true"
       @mouseleave="isDialog = false"
     ></v-icon-popup>

    <transition name="slide-fade">
      <div
        v-if="isDialog"
        :class='["popup-dialog", classDialog]'
      >
        <slot name="dialog"/>
      </div>
    </transition>
  </span>
</template>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";
.popup {
  cursor: help;
  position: relative;

  &-dialog {
    padding: 10px 15px;

    display: inline-block;
    max-width: 550px;
    width: 100%;
    min-width: 300px;

    border: 1px solid $color-gray;
    border-radius: 20px;
    background-color: #FFF;

    text-align: left;
    font-weight: 400;

    position: absolute;
    left: 40px;
    top: -40px;
    z-index: 5;
  }
}
</style>
