<template>
  <button
    class="btn btn--spinner btn-primary"
    :class="[`btn-bg-${color}`]"
    :disabled="isDisabled"
    @click="startSpinner"
  >
    <slot v-if="!isLongLoading" />
    <VSpinnerForButton v-else />
  </button>
</template>

<script>
import { computed, ref, watch } from 'vue'
import VSpinnerForButton from '@/components/views/Spinner/VSpinnerForButton'
import { DELAY_SEARCH } from '@/utils/consts'
export default {
  props: {
    isDisabled: {
      type: Boolean,
      required: false
    },
    isErrors: {
      type: Boolean,
      required: false
    },
    color: {
      type: String,
      validator: (val) => {
        const colors = ['green', 'yellow', 'transparent']
        return colors.includes(val)
      }
    }
  },
  setup (props) {
    const isLongLoading = ref(false)

    const startSpinner = () => {
      if (props.isErrors) return
      setTimeout(() => {
        isLongLoading.value = props.isDisabled
      }, DELAY_SEARCH)
    }

    const getIsLoading = computed(() => {
      return props.isDisabled
    })

    watch(getIsLoading, () => {
      if (getIsLoading.value === false) {
        isLongLoading.value = false
      }
    })

    return {
      isLongLoading,
      startSpinner
    }
  },
  components: { VSpinnerForButton }
}
</script>
