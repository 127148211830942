import { $authHost } from '@/http'

const USER_URL = '/users/fake/'

export const createFictiveBidder = async (id, formData) => {
  const { data } = await $authHost.post(`${USER_URL}${id}`, formData)
  return data
}

export const changeFictiveBidder = async (id, formData) => {
  const { data } = await $authHost.put(`${USER_URL}${id}`, formData)
  return data
}

export const getFictiveBidder = async (id) => {
  const { data } = await $authHost.get(`${USER_URL}${id}`)
  return data
}
